<script setup>
</script>

<template>
  <crud-details-page
    api="events/tournament"
    disable-top-margin
  >
    <template #form="{ form }">
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings
          api-field-name="NameTag"
          required
        />
        <!-- Mode -->
        <crud-field-select
          api-field-name="Mode"
          options-enum="enums.TournamentMode"
          required
        />
        <!-- NameTagExtra -->
        <crud-field-selector-client-strings
          v-if="form.Mode === 'National'"
          api-field-name="NameTagExtra"
          label="National Mixed Name Tag"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetLocation1 -->
        <crud-field-selector-files api-field-name="AssetLocation1" />
        <!-- AssetLocation2 -->
        <crud-field-selector-files api-field-name="AssetLocation2" />
        <!-- AssetLocation3 -->
        <crud-field-selector-files api-field-name="AssetLocation3" />
        <!-- AssetHomeIcon -->
        <crud-field-selector-files api-field-name="AssetHomeIcon" />
        <!-- AssetHomeBanner -->
        <crud-field-selector-files api-field-name="AssetHomeBanner" />
        <!-- AssetLocationPreview -->
        <crud-field-selector-files api-field-name="AssetLocationPreview" />
        <!-- AssetHomeCalendarIcon -->
        <crud-field-selector-files api-field-name="AssetHomeCalendarIcon" />
        <!-- AssetRankingBanner -->
        <crud-field-selector-files api-field-name="AssetCalendarBanner" />
        <!-- AssetCalendarBackground -->
        <crud-field-selector-files api-field-name="AssetCalendarBackground" />
        <!-- AssetOvertimePopup -->
        <crud-field-selector-files api-field-name="AssetOvertimePopup" />
        <crud-field-selector-files api-field-name="AssetCompetitionsBanner" />
      </fields-col>
      <fields-col :sm="8">
        <crud-field-selector-files
          v-for="i in 10"
          :key="i"
          :api-field-name="`AssetLocationPlace${i}`"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
