<script setup>
</script>

<template>
  <crud-details-page
    api="rewards/hit-three-event-reward"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- Color -->
        <crud-field-text api-field-name="Color" />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetPrize -->
        <crud-field-selector-files api-field-name="AssetPrize" />
      </fields-col>
      <fields-col
        v-for="i in 6"
        :key="i"
      >
        <section-divider>Ranking Reward {{ i }}</section-divider>
        <fields-col :sm="8">
          <!-- XpPct -->
          <crud-field-number
            :api-field-name="`RankingReward${i}.XpPct`"
            label="XpPct"
          />
          <!-- Energy -->
          <crud-field-number
            :api-field-name="`RankingReward${i}.Energy`"
            label="Energy"
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- Gems -->
          <crud-field-number
            :api-field-name="`RankingReward${i}.Gems`"
            label="Gems"
          />
          <fields-col :sm="16">
            <crud-field-selector-item
              :api-field-name="`RankingReward${i}.Item1`"
              label="Item"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`RankingReward${i}.Amount1`"
              label="Item amount"
            />
          </fields-col>
          <fields-col :sm="16">
            <crud-field-selector-item
              :api-field-name="`RankingReward${i}.Item2`"
              label="Item"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`RankingReward${i}.Amount2`"
              label="Item amount"
            />
          </fields-col>
        </fields-col>
        <fields-col :sm="8">
          <fields-col :sm="16">
            <crud-field-selector-item
              :api-field-name="`RankingReward${i}.Item3`"
              label="Item"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`RankingReward${i}.Amount3`"
              label="Item amount"
            />
          </fields-col>
          <fields-col :sm="16">
            <crud-field-selector-item
              :api-field-name="`RankingReward${i}.Item4`"
              label="Item"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`RankingReward${i}.Amount4`"
              label="Item amount"
            />
          </fields-col>
          <fields-col :sm="16">
            <crud-field-selector-item
              :api-field-name="`RankingReward${i}.Item5`"
              label="Item"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`RankingReward${i}.Amount5`"
              label="Item amount"
            />
          </fields-col>
        </fields-col>
      </fields-col>
      <fields-col
        v-for="i in 25"
        :key="i"
      >
        <section-divider>Milestone {{ i }}</section-divider>
        <fields-col :sm="8">
          <crud-field-number
            :api-field-name="`MileReward${i}.Threshold`"
            label="Threshold"
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- XpPct -->
          <crud-field-number
            :api-field-name="`MileReward${i}.XpPct`"
            label="XpPct"
          />
          <!-- Energy -->
          <crud-field-number
            :api-field-name="`MileReward${i}.Energy`"
            label="Energy"
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- Gems -->
          <crud-field-number
            :api-field-name="`MileReward${i}.Gems`"
            label="Gems"
          />
          <fields-col :sm="16">
            <crud-field-selector-item
              :api-field-name="`MileReward${i}.Item`"
              label="Item"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`MileReward${i}.ItemAmount`"
              label="Item amount"
            />
          </fields-col>
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
