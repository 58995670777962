<script>
export default {
  data() {
    const apiFormKeys = ['default_message_expire_time_days']
    return {
      apiFormKeys,
      selectedTextarea: 'Contents'
    }
  },
  methods: {
    insertImage(args, form) {
      if (typeof form[this.selectedTextarea] === 'undefined') {
        form[this.selectedTextarea] = ''
      } else {
        form[this.selectedTextarea] += '\n'
      }

      if (this.$route?.query?.__game === 'MafiaRivals') {
        const n = args.AdminIcon.lastIndexOf('/')
        form[this.selectedTextarea] += `[file=${args.AdminIcon.substring(n + 1)}]`
      } else {
        form[this.selectedTextarea] += `[file=${args.ID}]`
      }

      this.$utils.nextLoopEvent().then(() => {
        form.insertImageModel = null
      })
    },
    setTextareaFocus(c) {
      this.selectedTextarea = c
    }
  }
}
</script>

<template>
  <crud-details-page
    api="tools/global-message"
    :api-form-keys="apiFormKeys"
  >
    <template #form="{ form }">
      <!-- Title -->
      <crud-field-text
        :sm="8"
        api-field-name="Title"
        required
      />
      <!-- TitleTag -->
      <crud-field-selector-client-strings
        :sm="8"
        api-field-name="TitleTag"
      />
      <!-- CreatedAt -->
      <crud-field-date-time
        :sm="5"
        api-field-name="CreatedAt"
        required
        col-break
      />
      <!-- Sender -->
      <crud-field-text
        :sm="8"
        api-field-name="Sender"
        required
      />
      <!-- SenderTag -->
      <crud-field-selector-client-strings
        :sm="8"
        api-field-name="SenderTag"
        col-break
      />
      <crud-field-switcher
        :sm="4"
        api-field-name="ShowOnHome"
        class="flex-none"
      />
      <!-- XpPct -->
      <crud-field-number
        :sm="4"
        api-field-name="XpPct"
        col-break
      />
      <!-- Energy -->
      <crud-field-number
        :sm="4"
        api-field-name="Energy"
        col-break
      />
      <!-- Gems -->
      <crud-field-number
        :sm="4"
        api-field-name="Gems"
        col-break
      />
      <!-- AttackPointsGift -->
      <crud-field-number
        :sm="4"
        api-field-name="AttackPointsGift"
      />
      <crud-field-selector-files
        :sm="4"
        api-field-name="AssetHeader"
        col-break
      />
      <crud-field-number
        :sm="4"
        api-field-name="MinPlayerLevel"
        label="Minimum Player Level"
      />
      <crud-field-number
        :sm="4"
        api-field-name="CustomExpireTimeDays"
        label="Custom Expire Days"
        :placeholder="form?.default_message_expire_time_days ? `Default ${form.default_message_expire_time_days} days` : ``"
        col-break
      />
      <template
        v-for="i in 5"
        :key="i"
      >
        <crud-field-selector-item
          :sm="8"
          :api-field-name="`Item${i}`"
        />
        <crud-field-number
          :sm="4"
          :api-field-name="`Amount${i}`"
          col-break
        />
      </template>

      <section-divider>Translation</section-divider>
      <crud-field-selector-files
        :sm="12"
        api-field-name="insertImageModel"
        label="Insert Image"
        :disabled="!!form.Sent"
        :init-filters="{ Group: 'Messages' }"
        col-break
        vertical
        @change="insertImage($event, form)"
      >
        <template #fieldAppend>
          <div class="text-black-600 pl-1">
            max image width = <strong>520px</strong>
          </div>
        </template>
      </crud-field-selector-files>
      <crud-field-textarea
        :textarea-rows="5"
        api-field-name="Contents"
        label="EN"
        required
        @focus="setTextareaFocus('Contents')"
      />
      <crud-field-textarea
        v-for="field in $store.getters['auth/userLanguages']"
        :key="field"
        :textarea-rows="5"
        :api-field-name="`Contents${field.toUpperCase()}`"
        :label="field.toUpperCase()"
        @focus="setTextareaFocus(`Contents${field.toUpperCase()}`)"
      />
      <section-divider>Zones</section-divider>
      <crud-field-switcher
        api-field-name="ForAsia"
        class="flex-none"
      />
      <crud-field-switcher
        api-field-name="ForEurope"
        class="flex-none"
      />
      <crud-field-switcher
        api-field-name="ForAmerica"
        class="flex-none"
      />
    </template>
  </crud-details-page>
</template>
