<template>
  <crud-table
    :default-visible-columns="['Name']"
    api="hit-three-events"
    api-item="hit-three-event"
    show-img-preview-column
  >
    <template #topLeft>
      <a
        href="https://soccer-dev.netto-api-gamesture.com/api/ap/?redir=simulatealldices&pass=nhgriedjfgrj&is_bot=false&is_losing_or_skirmish=false&energy=1000&multiplier=15&crit_chance_bonus_pct=0&hit_three_event_id=1&simulations=100"
        target="_blank"
        class="text-blue-500 underline underline-offset-4 hover:text-blue-600 pl-2"
      >
        Simulate Dice
      </a>
    </template>
  </crud-table>
</template>
