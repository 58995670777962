<script>
import { Warning } from '@element-plus/icons-vue'

export default {
  components: { Warning },
  data() {
    return {
      loading: {},
      savedEnv: null,
      localForm: {
        tags: 'Player Name - <PLAYER>'
      },
      localExternalErrors: {}
    }
  },
  computed: {
    serversOptions() {
      return Object.entries(this.$store.getters['auth/envs'])
        .map(([env]) => ({ value: env, label: env }))
        .filter(opt => opt.value !== 'local')
    }
  },
  methods: {
    insertImage(args, form) {
      if (typeof form.Contents === 'undefined') {
        form.Contents = ''
      } else {
        form.Contents += '\n'
      }

      if (this.$route?.query?.__game === 'MafiaRivals') {
        const n = args.AdminIcon.lastIndexOf('/')
        form.Contents += `[file=${args.AdminIcon.substring(n + 1)}]`
      } else {
        form.Contents += `[file=${args.ID}]`
      }

      this.$utils.nextLoopEvent().then(() => {
        form.insertImageModel = null
      })
    },
    send(form, refreshData) {
      if (form.Env && form.ID) {
        this.loading.send = true
        this.$axios
          .get(`${this.$store.getters['auth/envs'][form.Env]}/proxy/sendpersonalmessage/${form.ID}/`, {
            params: { env: form.Env }
          })
          .then(() => {
            this.$message({
              message: 'Has been sent.',
              type: 'success',
              offset: 40
            })
            refreshData()
          })
          .catch(this.$utils.catchError)
          .then(() => {
            this.loading.send = false
          })
      }
    },
    onDuplicate(duplicateData) {
      duplicateData.Sent = null
      return duplicateData
    },
    initItem(form) {
      this.savedEnv = form?.Env
      if (this.$route.query.playerCSV !== undefined) {
        form.PlayerIDs = this.$route.query.playerCSV
      }
      return form
    }
  }
}
</script>

<template>
  <crud-details-page
    api="tools/personal-message"
    disable-top-margin
    :versioned="false"
    :render-init-item="initItem"
    :render-duplicate-item="onDuplicate"
    re-load-data-after-save
  >
    <template #form="{ form, refreshData }">
      <fields-col :sm="8">
        <!-- Sender -->
        <crud-field-text
          api-field-name="Sender"
          required
        />
        <!-- Title -->
        <crud-field-text
          api-field-name="Title"
          required
        />
        <!-- PlayerIDs -->
        <crud-field-text
          api-field-name="PlayerIDs"
          label="Player CSV ids"
          required
        />
        <!-- XpPct -->
        <crud-field-number
          :sm="12"
          api-field-name="XpPct"
        />
        <!-- Energy -->
        <crud-field-number
          :sm="12"
          api-field-name="Energy"
        />
        <!-- Gems -->
        <crud-field-number
          :sm="12"
          api-field-name="Gems"
        />
        <!-- AttackPointsGift -->
        <crud-field-number
          :sm="12"
          api-field-name="AttackPointsGift"
        />
        <crud-field-selector-files
          :sm="12"
          api-field-name="AssetHeader"
        />
        <template
          v-for="i in 5"
          :key="i"
        >
          <crud-field-selector-item
            :sm="16"
            :api-field-name="`Item${i}`"
          />
          <crud-field-number
            :sm="8"
            :api-field-name="`Amount${i}`"
          />
        </template>
        <!-- Region -->
        <crud-field-select
          :sm="10"
          api-field-name="Env"
          :options="serversOptions"
          label="Env"
          required
          :clearable="false"
          :disabled="!!form.Sent"
        />
        <crud-field-slot
          :sm="14"
          label="send"
          empty-label
        >
          <div
            v-if="form.ID && form.Sent === null"
            class="flex items-center"
          >
            <el-popconfirm
              title="Are you sure?"
              @confirm="send(form, refreshData)"
            >
              <template #reference>
                <el-button
                  class="gs-loading gs-font-scaled gs-height-related-xl gs-btn-outlined-warning ml-2 px-3"
                  :loading="loading.send"
                  :disabled="!form.Env || savedEnv !== form.Env"
                >
                  Send
                </el-button>
              </template>
            </el-popconfirm>
            <template v-if="!form.Env || savedEnv !== form.Env">
              <el-tooltip
                effect="light"
                content="need to save Env to activate"
              >
                <el-icon class="gs-font-scaled gs-scaled-icon-md ml-1 cursor-help text-orange-300">
                  <Warning />
                </el-icon>
              </el-tooltip>
            </template>
          </div>
        </crud-field-slot>
        <crud-field-slot
          v-if="form.Sent !== null && form?.ID"
          label="Sent"
        >
          <span>{{ $utils.formatUTCDateTime(form.Sent) }}</span>
        </crud-field-slot>
      </fields-col>
      <fields-col :sm="16">
        <!-- Title -->
        <crud-field-textarea
          api-field-name="Contents"
          :textarea-rows="25"
          label="Message content"
          required
        />
        <crud-field-selector-files
          :sm="12"
          api-field-name="insertImageModel"
          label="Insert Image"
          :disabled="!!form.Sent"
          :init-filters="{ Group: 'Messages' }"
          vertical
          @change="insertImage($event, form)"
        >
          <template #fieldAppend>
            <div class="text-black-600 pl-1">
              max image width = <strong>520px</strong>
            </div>
          </template>
        </crud-field-selector-files>
      </fields-col>
      <crud-field-textarea
        api-field-name="tags"
        disabled
        label="Tags"
        :form="localForm"
        :external-errors="localExternalErrors"
      />
    </template>
  </crud-details-page>
</template>
